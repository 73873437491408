<template>
    <div class="">
        <div class=" bg-[#f8ecee]">
            <header class="flex mx-auto flex-col max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-none 2xl:px-12 py-8 md:py-6 ts:py-3 2xl:py-8">
                <!-- logo -->
                <section class="flex flex-row justify-between relative">
                    <div class="hidden ts:flex items-center justify-between min-w-[200px]">
                        <div class="block md:hidden cursor-pointer py-2" @mouseenter="menu = true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#16635a" height="24" width="24" version="1.1" id="Layer_1" viewBox="0 0 150 150" xml:space="preserve">
                                <g id="XMLID_240_">
                                    <path id="XMLID_241_" d="M15,30h120c8.284,0,15-6.716,15-15s-6.716-15-15-15H15C6.716,0,0,6.716,0,15S6.716,30,15,30z" />
                                    <path id="XMLID_242_" d="M135,60H15C6.716,60,0,66.716,0,75s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,60,135,60z" />
                                    <path id="XMLID_243_" d="M135,120H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,120,135,120z" />
                                </g>
                            </svg>
                        </div>

                        <div class="hidden lg:block pr-2 cursor-pointer" @mouseenter="menu = true">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#16635a" height="28" width="32" version="1.1" id="Layer_1" viewBox="0 0 150 150" xml:space="preserve">
                                <g id="XMLID_240_">
                                    <path id="XMLID_241_" d="M15,30h120c8.284,0,15-6.716,15-15s-6.716-15-15-15H15C6.716,0,0,6.716,0,15S6.716,30,15,30z" />
                                    <path id="XMLID_242_" d="M135,60H15C6.716,60,0,66.716,0,75s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,60,135,60z" />
                                    <path id="XMLID_243_" d="M135,120H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,120,135,120z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="mr-4 hidden ts:block">
                        <div v-if="logoLink == '/'">
                            <Link :href="logoLink"><img class="w-full max-w-[250px]" :id="this.$inertia.page.url" src="../../../gemfinite/gemfinite-logo.png" alt="" /></Link>
                        </div>
                        <div v-else>
                            <a :href="logoLink"><img class="w-full max-w-[250px]" :id="this.$inertia.page.url" src="../../../gemfinite/gemfinite-logo.png" alt="" /></a>
                        </div>
                    </div>
                    <BasketNotification
                        :show="$page.props.flash.basketStatus?.type == 'added' ? true : false // setTimeout(() => { // basketNotificationShown = false; // }, 2000)
"
                    />
                    <div class="hidden ts:flex items-center">
                        <ul class="flex space-x-4 items-center">
                            <li class="text-sm md:text-base hidden md:block relative">
                                <div class="group  w-full rounded-xl flex items-center relative">
                                    <input type="text" class="peer z-80 cursor-pointer placeholder-transparent w-[50px] focus:w-[300px] border-none rounded-xl py-1 px-5 text-base transition-all ease-linear duration-1000 delay-100 bg-transparent focus:border-none focus:ring-kbgreen focus:bg-white bg-transparent focus:shadow-searchshadow" v-model="searchForm.term" @keydown.enter="submitSearchForm" id="searchform" placeholder="Marka, ürün adı veya kategori yaz..." />
                                    <label for="searchform"
                                           class="absolute left-4 top-0 transform transition-all peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-full -translate-y-full text-transparent text-xs peer-placeholder-shown:text-[11px] md:peer-placeholder-shown:text-sm peer-placeholder-shown:text-placeholdergray peer-placeholder-shown:top-4 md:peer-placeholder-shown:top-2 peer-placeholder-shown:left-4 peer-focus:top-0 peer-focus:left-4 peer-focus:text-transparent peer-focus:text-xs">
                                    </label>
                                    <div class=" absolute top-1 right-0" @click="submitSearchForm">
                                        <img src="../../../images/svg/search.svg" alt="" />
                                    </div>
                                </div>
                            </li>
                            <li class="text-sm md:text-base relative">
                                <Link href="/sepetim" class="flex">
                                    <span class="font-santralextrabold text-xs lg:text-2xs 2xl:text-xs text-white bg-kbgreen rounded-full absolute w-4 h-4 font-normal -bottom-1 -right-1 flex justify-center items-center leading-none">{{ auth.cart.total }}</span>
                                    <svg class="w-6 h-6 ml-1 stroke-[#231f20] overflow-visible" viewBox="0 0 30.324 31.234">
                                        <g id="Group_3491" data-name="Group 3491" transform="translate(1.51 1.5)">
                                            <path
                                                id="Path_2958"
                                                data-name="Path 2958"
                                                d="M212.736,218.3H199.28c-2.21,0-4.076-1.109-4.357-2.59l-2.53-13.314c-.337-1.774,1.709-3.345,4.357-3.345h18.515c2.648,0,4.695,1.571,4.358,3.345l-2.53,13.314C216.812,217.194,214.946,218.3,212.736,218.3Z"
                                                transform="translate(-192.356 -190.068)"
                                                fill="none"
                                                stroke="#231f20"
                                                stroke-miterlimit="10"
                                                stroke-width="3"
                                            />
                                            <path id="Path_2959" data-name="Path 2959" d="M202.477,192.194l5.167-7.892c.942-1.439,2.268-1.46,3.222-.052l5.381,7.944" transform="translate(-195.558 -183.208)" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width="3" />
                                        </g>
                                    </svg>
                                </Link>
                            </li>
                            <li class="text-sm md:text-base hidden md:block relative" v-if="auth.isUserLoggedIn">
                                <Link href="/profili-duzenle" class="flex">

                                    <div class="font-santralextrabold text-xs leading-none text-kbgreen w-7 h-7 border-2 rounded-full border-black flex justify-center leading-none items-center ml-1">
                                        {{
                                            auth.user?.full_name
                                                .split(" ")
                                                .map((n) => n[0])
                                                .join("")
                                        }}
                                    </div>
                                </Link>
                            </li>
                            <li class="text-sm md:text-base hidden md:block" v-if="!auth.isUserLoggedIn">
                                <Link :href="`/giris-yap`" class="flex">
                                    <img class="w-6 h-6" src="https://cdn.iconscout.com/icon/free/png-512/free-user-1772944-1508886.png?f=webp&w=512" alt="">
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                <NewMenu :auth="auth" :logoUrl="logoUrl" :wishlist="wishlist" :cart="cart" :open="false" :show="true"></NewMenu>
                <!-- search -->
                <section class="relative">
                    <Menu :active="menu" @menuchange="(val) => (menu = val)" :menuItems="menuItems"></Menu>
                    <!--                <MobileMenu :active="menu" @menuchange="(val) => (menu = val)" :menuItems="menuItemsMobile" :auth="auth" :wishlist="wishlist"></MobileMenu>-->
                </section>
                <!-- categories & menu -->
            </header>
        </div>
        <slot />
        <footer>
            <section class="bg-kbgreen" v-if="!auth.isUserLoggedIn">
                <div class="flex flex-col mx-auto max-w-kbmobile mts:max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                    <div class="text-white text-base md:text-2xl lg:text-2xl text-center mt-4 max-w-[242px] md:max-w-none mx-auto">Yenilikler ve indirimler için bültenimize abone ol.</div>
                    <form class="text-center mb-6 mt-6 flex justify-center">
                        <input type="email" v-model="subscribeForm.email" class="rounded-l-2lg mts:w-8/12 lg:w-4/12 leading-[70px] h-10 placeholder:text-sm md:placeholder:text-lg placeholder:text-placeholdergray py-6 md:px-6 text-base focus:outline-none" placeholder="E-Posta Adresin" required />
                        <button type="submit" @click="openSubscribeModal" class="bg-[#eac7cd] text-base font-santralextrabold text-kbgreen px-8 py-3 rounded-r-2lg border-0 focus:outline-none whitespace-nowrap">Abone Ol</button>
                    </form>
                </div>
            </section>
            <section class="my-3 md:my-4 md:mt-4">
                <div class="flex flex-wrap lg:flex-nowrap mx-auto max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl lg:space-x-8">
                    <div class="w-6/12 md:w-6/12 lg:w-3/12 my-2 lg:my-0">
                        <div class="text-sm mts:text-base mt-3">GEMFİNİTE</div>
                        <div class="flex flex-col mt-4 md:mt-8 leading-4">
                            <Link href="/" class="text-kbgray text-xs mts:text-sm mb-2">Anasayfa</Link>
                            <Link href="/hakkimizda" class="text-kbgray text-xs mts:text-sm mb-2">Hakkımızda</Link>
                            <Link href="/olcu-kilavuzu" class="text-kbgray text-xs mts:text-sm mb-2">Ölçü Kılavuzu</Link>
                            <Link href="/sikca-sorulan-sorular" class="text-kbgray text-xs mts:text-sm mb-2">SSS</Link>
                            <Link href="/iletisim" class="text-kbgray text-xs mts:text-sm mb-2">İletişim</Link>
                        </div>
                    </div>
                    <div class="w-6/12 md:w-6/12 lg:w-3/12 my-2 lg:my-0 pl-2 md:pl-0">
                        <div class="text-sm mts:text-base mt-3">BİLGİLENDİRME</div>
                        <div class="flex flex-col mt-4 md:mt-8 leading-4">
                            <Link href="/uyelik-sozlesmesi" class="text-kbgray text-xs mts:text-sm mb-2">Üyelik Sözleşmesi</Link>
                            <Link href="/aydinlatma-metni" class="text-kbgray text-xs mts:text-sm mb-2">Aydınlatma Metni</Link>
                            <Link href="/cayma-fesih-ve-iade-kosullari" class="text-kbgray text-xs mts:text-sm mb-2"> Cayma, Fesih ve İade Koşulları</Link>
                            <Link href="/cerez-politikasi" class="text-kbgray text-xs mts:text-sm mb-2">Çerez (Cookie) Politikası</Link>
                            <Link href="/kimlik-findeks-raporu" class="text-kbgray text-xs mts:text-sm mb-2">Kimlik ve Findeks Raporu</Link>
                            <Link href="/odeme-ve-teslimat" class="text-kbgray text-xs mts:text-sm mb-2">Ödeme ve Teslimat</Link>
                        </div>
                    </div>
                    <div class="w-full mts:w-6/12 lg:w-4/12 flex flex-col justify-center lg:justify-start lg:my-0">
                        <div class="text-sm mts:text-base mt-3">İLETİŞİM</div>
                        <div class="flex flex-col mt-4 lg:mt-8 leading-4">
                            <!--                            <p class="text-kbgray text-sm lg:text-sm">Hızlı destek için </p>-->
                            <!--                            <a href="tel:+908502551552" class="text-kbgreen text-3xl lg:text-3xl lg:mt-2 font-semibold">0850 255 1552</a>-->
                            <div class="flex">
                                <div class="text-kbgray text-xs mts:text-sm mb-2">Hızlı destek için</div>
                            </div>
                            <a href="https://wa.me/+905304001412" target="_blank">
                                <button class="bg-kbgreen text-white text-xs lg:text-sm py-2 w-[240px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold">Whatsapp ile Bağlan</button>
                            </a>
                        </div>
                    </div>
                    <div class="w-full mts:w-6/12 lg:w-3/12 my-2 lg:my-0">
                        <div class="text-sm mts:text-base mt-3">BİZİ TAKİP ET</div>
                        <ul class="flex flex-row flex-wrap mts:flex-col mt-2 leading-4 space-y-2 mts:space-y-4">
                            <li class="flex w-1/2 mts:w-full items-center mt-2 mts:mt-4">
                                <a href="https://www.facebook.com/gemfinite" target="_blank" class="flex justify-center items-center bg-kbgreen rounded-full w-8 h-8">
                                    <svg width="7.385" height="14" viewBox="0 0 7.385 16" class="">
                                        <path id="" data-name="Facebook Icon" d="M6.981,2.83A4.442,4.442,0,0,0,5.69,2.617c-.525,0-1.654.362-1.654,1.064V5.362H6.719v2.83H4.035V16h-2.7V8.191H0V5.362H1.332V3.936C1.332,1.787,2.26,0,4.5,0A10.187,10.187,0,0,1,7.385.319Z" fill="#f8ecee" />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/gemfinite" target="_blank">
                                    <span class="text-kbgray text-xs mts:text-sm ml-2">Facebook</span>
                                </a>
                            </li>
                            <li class="flex w-1/2 mts:w-full items-center pl-2 mts:pl-0">
                                <a href="https://www.instagram.com/gemfinitecom/" target="_blank" class="flex justify-center items-center bg-kbgreen rounded-full w-8 h-8">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="16" height="116">
                                        <path
                                            fill="#f8ecee"
                                            d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/gemfinitecom/" target="_blank">
                                    <span class="text-kbgray text-xs mts:text-sm ml-2">Instagram</span>
                                </a>
                            </li>
                            <li class="flex w-1/2 mts:w-full items-center">
                                <a href="https://www.linkedin.com/company/kiralabunu" target="_blank" class="flex justify-center items-center bg-kbgreen rounded-full w-8 h-8">
                                    <svg width="16" height="16" viewBox="0 0 16 16" class="scale-75">
                                        <path
                                            id="LinkedIn_Icon"
                                            data-name="LinkedIn Icon"
                                            d="M8.96,16H5.547q0-.937,0-1.872v-.006h0c0-3.042.008-6.189-.082-9.295h2.96L8.587,6.4H8.64a3.988,3.988,0,0,1,3.414-1.813,3.507,3.507,0,0,1,3.033,1.453A5.785,5.785,0,0,1,16,9.412V16H12.56V9.813c0-1.611-.583-2.427-1.733-2.427a1.909,1.909,0,0,0-1.76,1.307,2.518,2.518,0,0,0-.106.88V16ZM3.493,16H.08V4.826H3.493V16ZM1.76,3.467A1.69,1.69,0,0,1,0,1.733,1.716,1.716,0,0,1,1.813,0,1.7,1.7,0,0,1,3.6,1.733,1.711,1.711,0,0,1,1.76,3.467Z"
                                            fill="#f8ecee"
                                        />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/kiralabunu" target="_blank">
                                    <span class="text-kbgray text-xs mts:text-sm ml-2">Linkedin</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div class="mt-6 block md:hidden max-w-kbmobile mx-auto md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mb-7">
                <img src="../../../images/logos-white.png" alt="" />
            </div>
            <div class="bg-[#f5f5f5] pt-2 pb-2">
                <div class="max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
                    <div class="flex flex-col md:flex-row justify-between items-center">
                        <div class="flex">
                            <!--                            <div class="text-sm text-kbgray">-->
                            <!--                                <a href="">Şartlar ve Koşullar</a>-->
                            <!--                            </div>-->
                            <!--                            <div class="text-sm text-kbgray mx-1">|</div>-->
                            <div class="text-sm text-kbgray">
                                <Link href="/kvkk"> Gizlilik Politikası</Link>
                            </div>
                        </div>
                        <div class="hidden lg:block">
                            <img src="../../../images/logocard.png" alt="" />
                        </div>
                        <div class="text-sm text-kbgray mx-1">
                            Gemfinite bir Kiralabunu kuruluşudur. <br>
                            gemfinite.com © 2024 Tüm Hakları Saklıdır
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                        <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white px-5 py-5 z-70 text-left align-middle shadow-xl transition-all flex flex-col justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 62.051 62.051">
                                <g id="Group_4884" data-name="Group 4884" transform="translate(-73.036 -250.295)">
                                    <circle id="Ellipse_199" data-name="Ellipse 199" cx="31.025" cy="31.025" r="31.025" transform="translate(73.036 250.295)" fill="#70d44b" />
                                    <path id="Path_3299" data-name="Path 3299" d="M351.649,476.232a3.45,3.45,0,0,1-2.761-1.381l-8.464-11.286a3.451,3.451,0,0,1,5.522-4.141l5.538,7.383,14.223-21.334a3.451,3.451,0,1,1,5.743,3.829l-16.928,25.393a3.453,3.453,0,0,1-2.776,1.536Z" transform="translate(-251.82 -178.764)" fill="#fff" />
                                </g>
                            </svg>
                            <DialogTitle>
                                <h2 class="text-base font-semibold text-center mb-2 mt-2">Teşekkürler</h2>
                            </DialogTitle>
                            <p class="text-xs font-kiralabunuthin ts:text-sm text-center">Yenilikler ve indirimler için aboneliğiniz oluşturuldu.</p>
                            <!--                            <div class="mt-4">-->
                            <!--                                <button-->
                            <!--                                    type="button"-->
                            <!--                                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"-->
                            <!--                                    @click="closeModal"-->
                            <!--                                >-->
                            <!--                                    Got it, thanks!-->
                            <!--                                </button>-->
                            <!--                            </div>-->
                            <div class="absolute top-2 right-5 cursor-pointer p-2" @click="closeModal">x</div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { computed } from "vue";
import { Link, usePage } from "@inertiajs/inertia-vue3";
import Menu from "@/Pages/Shared/Menu.vue";
import NewMenu from "@/Pages/Shared/NewMenu.vue";
// import MobileMenu from "@/Pages/MobileMenu.vue";
import BasketNotification from "@/Pages/Shared/basketNotification.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import basketNotification from "@/Pages/Shared/basketNotification.vue";

export default {
    computed: {
        basketNotification() {
            return basketNotification;
        }
    },
    components: {
        BasketNotification,
        // MobileMenu,
        Link,
        Menu,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        NewMenu
    },
    data() {
        return {
            menu: false,
            userCart: null,
            userCartLength: 0,
            searchForm: this.$inertia.form({
                term: null
            }),
            subscribeForm: this.$inertia.form({
                email: null
            }),
            logoUrl: "/images/logo.png",
            logoLink: "/",
            hideTopMenu: false,
            isOpen: false,
            hiddenbtn1: true,
            hiddenbtn2: true,
            hiddenbtn3: true,
            hiddenbtn4: true
        };
    },
    props: {
        menuItems: Object,
        menuItemsMobile: Object,
        auth: Object,
        apiEP: String,
        wishlist: Number,
        cart: Number
    },
    methods: {
        // toggleMenu() {
        //     this.menu = false;
        //     setTimeout(() => {
        //         //this.menu = false;
        //     }, 300);
        // },
        closeModal() {
            console.log(this.isOpen);
            this.isOpen = false;
        },
        openSubscribeModal() {
            if (this.subscribeForm.email == null || this.subscribeForm.email == "") return;

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.subscribeForm.email)) {
                this.isOpen = true;
            }
        },
        submitSearchForm() {
            // Arama metni boşsa arama yapma
            if (this.searchForm.term == null || this.searchForm.term == "") return;
            this.searchForm.get(route("searchResults"));
        },
        newMessage(something) {
            console.log("newMessage", something);
            //this.$vueEventBus.$emit("message-in", something);
        }
    },
    mounted() {
        this.userCart = JSON.parse(localStorage.getItem("userCart")) || [];
        // this.userCartLength = this.userCart.length;
    },
    created() {
        this.$inertia.on("navigate", (event) => {
            this.logoUrl = "/images/logo.png";
            if (event.detail.page.url == "/kurumsal") this.logoUrl = "/images/kb-kurumsal.svg";
            if (event.detail.page.url.endsWith("?teknosa")) {
                this.logoUrl = "https://img-teknosa-sap.mncdn.com/_ui/responsive/theme-teknosa/assets/images/logo.svg";
                this.hideTopMenu = true;
                this.logoLink = "https://teknosa.com";
            }

            dataLayer.push({
                pageView: event.detail.page.url,
                event: "virtualPageView"
            });
        });

        window.axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth.token}`;

        if (this.auth.isUserLoggedIn && this.auth.user == null) {
            axios.get(`${this.apiEP}auth/user/cart/get-auth`).then((res) => {
                this.auth.user = res.data;
                window.localStorage.setItem("user", JSON.stringify(res.data));
            });
        } else if (this.auth.isUserLoggedIn && this.auth.user != null) {
            window.localStorage.setItem("user", JSON.stringify(this.auth.user));
        }
    }
    // setup() {
    //     const alert = computed(() => usePage().props.value.flash.alert);
    //     return { alert };
    // },
};
</script>

<style scoped></style>
